import {
    Checkbox,
    DefaultButton,
    FontWeights,
    Label, Link,
    mergeStyles,
    PrimaryButton,
    Spinner,
    Stack,
    StackItem,
    Text,
    useTheme
} from "@fluentui/react";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from 'react-intl';
import {
    IAttachment,
    IJobCommentDocument,
    IJobCommentResponse,
    IJobCommentUpdateBody,
    useGetJobAttachments,
    useUpdateJobComments,
} from '../../hooks';
import { StandartPointSection } from './StandartPointSection';
import {
    CharacterMetadata,
    CompositeDecorator,
    ContentBlock,
    ContentState, convertToRaw, DraftHandleValue,
    Editor,
    EditorState,
    Entity,
    Modifier,
    SelectionState
} from "draft-js";
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { useGetJobByGuid } from 'hooks/useGetJobByGuid';
import { useNavigate, useParams } from "react-router-dom";
import { JobCommentLinkedDocuments } from './JobCommentLinkedDocuments';
import { useJobRedirects } from "../templates/shared";
import jsonBeautify from 'json-beautify';
import * as repl from "repl";
import { useWorkContext } from "../../../../providers";
import { useTabContext } from "../../JobPortalPage";

interface IJobCommentFormProps {
    data: IJobCommentResponse;
    onCancel: Function;
    onSuccessUpdate: Function;
}

interface IJobCommentForm {
    comment: EditorState;
    permanentComment: EditorState;
}

interface IJobExternalCommentForm {
    external: EditorState;
}

const LINK_REGEX = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const JobCommentForm: FunctionComponent<IJobCommentFormProps> = ({ data, onCancel, onSuccessUpdate }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { guid } = useParams();
    const { isTabEnabled } = useTabContext();
    const { dataJobs } = useGetJobByGuid({ guid });
    const { attachmentsData, isAttachmentsLoading, isJobAttachmentsFetching } = useGetJobAttachments({
        jobId: dataJobs?.data?.id,
        fundId: dataJobs?.data?.fund?.id,
        year: dataJobs?.data?.year,
    });

    if (
        data?.permanentComment?.year &&
        dataJobs?.data?.year &&
        dataJobs.data.year > data.permanentComment.year &&
        !data.permanentComment.isActivated
    ) {
        data.permanentComment = null;
    }

    const { updateJobComment, isLoading: isUpdateCommentLoading } = useUpdateJobComments();
    const [isPerm, setPerm] = useState<boolean>(data?.permanentComment?.isActivated || false);

    const [isExternalHidden, setIsExternalHidden] = useState<boolean | null>(
        (!data?.externalComment || data.externalComment.isHidden === true)
        && (!data.externalPermanentComment || data.externalPermanentComment.isHidden === true));

    const [showTransferredAttachments, setShowTransferredAttachments] = useState(!!data?.externalComment || data?.externalPermanentComment);

    const findLinkEntities = (contentBlock: ContentBlock, callback: any, state: ContentState) => {
        contentBlock.findEntityRanges(
            (character: CharacterMetadata) => {
                const entityKey = character.getEntity();

                return (
                    entityKey !== null &&
                    Entity.get(entityKey).getType() === 'LINK'
                )
            },
            callback
        )
    }
    const decorator = new CompositeDecorator([
        { strategy: findLinkEntities, component: EditorLink }
    ])

    const [formState, setFormState] = useState<IJobCommentForm>({
        comment: EditorState.push(EditorState.createEmpty(decorator), convertFromHTML(data?.comment?.htmlText || ''), 'insert-characters'),
        permanentComment: EditorState.push(EditorState.createEmpty(decorator), convertFromHTML(data?.permanentComment?.htmlText || ''), 'insert-characters')
    });

    const [externalFormState, setExternalFormState] = useState<IJobExternalCommentForm>({
        external: EditorState.push(EditorState.createEmpty(decorator),
            convertFromHTML(
                `<b>${formatMessage({id: 'permanentCommentForFutureJobs'})}</b><br>` +
                (data?.externalPermanentComment?.htmlText || '') + 
                '<br>' +
                `<b>${formatMessage({id: 'currentYearComment'})}</b><br>` +
                (data?.externalComment?.htmlText || '')),
            'insert-characters')
    });

    const handleOnChange = (state: EditorState, key: keyof IJobCommentForm | keyof IJobExternalCommentForm) => {
        let modifiedState: EditorState = state;
        
        const blocks = modifiedState.getCurrentContent().getBlocksAsArray();
        blocks.forEach((block: ContentBlock, blockIndex: number) => {
            const contentState = modifiedState.getCurrentContent();
            
            const blockContent = block.getText();
            // console.debug(key + ": [BLOCK]: " + block.getKey() + '.', blockContent);
            if (!blockContent) {
                return;
            }
            
            const matches = blockContent.match(LINK_REGEX);
            if (!matches) {
                return;
            }
            matches.forEach((match: string, matchIndex: number) => {
                const startIndex = blockContent.indexOf(match);
                const endIndex = startIndex + match.length;
                
                const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: match });
                
                // if (key === 'permanentComment') {
                //     console.debug("[MATCH]", startIndex, endIndex, contentStateWithEntity.getPlainText());
                // }
                
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                
                const selection = new SelectionState({
                    anchorKey: block.getKey(),
                    focusKey: block.getKey(),
                    anchorOffset: startIndex,
                    focusOffset: endIndex
                });
                
                const newContentState = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);
                modifiedState = EditorState.set(modifiedState, { currentContent: newContentState })
                EditorState.forceSelection(modifiedState, newContentState.getSelectionAfter());
            })
        })
        
        // if (key === 'permanentComment'){
        //     const raw_2 = jsonBeautify(convertToRaw((modifiedState as EditorState).getCurrentContent()), null as any, 2, 50);
        //     console.log("[MODIFIED_STATE]", raw_2);
        // }
        
        if (key === 'external') {
            setExternalFormState((prev) => ({ ...prev, [key]: modifiedState }));
            return;
        }
        setFormState((prev) => ({ ...prev, [key]: modifiedState }));
    }
    
    const [selectedPermDocuments, setSelectedPermDocuments] = useState<IAttachment[]>(
        (data?.permanentComment?.documents || []).map((d) => d.attachment!)
    );
    const [selectedDocuments, setSelectedDocuments] = useState<IAttachment[]>(
        attachmentsData?.data?.filter((a) => data?.comment?.documents?.some((d) => d.attachmentId === a.id)) || []
    );

    const onAddToPermanent = (text: string) => {
        shiftTextInEditor(text, 'permanentComment');
    };

    const onAddToCurrentYear = (text: string) => {
        shiftTextInEditor(text, 'comment');
    };

    const shiftTextInEditor = (text: string, stateKey: 'comment' | 'permanentComment') => {
        const currentContent = formState[stateKey].getCurrentContent();
        const blockMap = currentContent.getBlockMap();

        const key = blockMap.first().getKey();
        const selection = new SelectionState({
            anchorKey: key,
            anchorOffset: 0,
            focusKey: key,
            focusOffset: 0,
        });

        const newContent = Modifier.insertText(currentContent, selection, `${text}\n`);

        const newEditorState = EditorState.push(formState[stateKey], newContent, 'insert-characters');

        EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());

        setFormState((prev) => ({ ...prev, [stateKey]: newEditorState }));
    };

    const styles = mergeStyles({
        selectors: {
            '.public-DraftEditor-content': {
                minHeight: 120,
            },
        },
    });
    
    const replaceToHTMLLinks = (text: string): string => {
        if (!text) {
            return text;
        }
    
        const matches = text.match(LINK_REGEX);
        let textReplaced = text;
    
        if (matches?.length) {
            const replacedLinks = new Set<string>();
            matches.forEach((match: string) => {
                if (replacedLinks.has(match)) {
                    return;
                }
    
                const text = match;
                let href = match;
                if (href.indexOf('://') === -1) {
                    href = `https://${href}`;
                }
    
                textReplaced = textReplaced.replaceAll(
                    match,
                    `<a href="${href}" style="color:#61b0e9" target="_blank">${text}</a>`
                );
    
                replacedLinks.add(match);
            });
        }
        return textReplaced;
    }

    const onSave = () => {
        const comment = data.comment || {};
        const permanentComment = data.permanentComment || {};

        const commentContent = formState.comment.getCurrentContent();
        const permanentCommentContent = formState.permanentComment.getCurrentContent();

        const commentText = commentContent.getPlainText();
        const permanentCommentText = permanentCommentContent.getPlainText();

        const commentHTMLText = convertToHTML(commentContent);
        const permanentCommentHTMLText = convertToHTML(permanentCommentContent);

        const getNewDocuments = (selectedAttachments: IAttachment[], documentsPrev: IJobCommentDocument[]): IJobCommentDocument[] => {
            return selectedAttachments.map((selected) => {
                const targetDocument = documentsPrev.find((dPrev) => dPrev.attachmentId === selected.id);
                return (
                    targetDocument || {
                        commentId: permanentComment.id,
                        isPerm: false,
                        attachmentId: selected.id,
                    }
                );
            });
        };

        const permanentDocuments: IJobCommentDocument[] = getNewDocuments(selectedPermDocuments, data.permanentComment?.documents || []);
        const documents: IJobCommentDocument[] = getNewDocuments(selectedDocuments, data.comment?.documents || []);

        const updBody: IJobCommentUpdateBody = {
            fundId: dataJobs?.data?.fund?.id,
            itemId: data?.itemId,
            jobId: dataJobs?.data?.id,
            comment: {
                itemId: data?.itemId,
                tableType: data?.tableType,
                ...comment,
                text: commentText,
                htmlText: replaceToHTMLLinks(commentHTMLText),
                documents,
            },
            permanentComment: {
                itemId: data?.itemId,
                tableType: data?.tableType,
                fundId: dataJobs?.data?.fund?.id,
                year: dataJobs?.data?.year,
                ...permanentComment,
                isActivated: isPerm,
                text: permanentCommentText,
                htmlText: replaceToHTMLLinks(permanentCommentHTMLText),
                documents: permanentDocuments,
            },
            externalComment: data?.externalComment,
            externalPermanentComment: data?.externalPermanentComment,
            isExternalHidden: isExternalHidden
        };

        updateJobComment(
            {
                fundId: dataJobs?.data?.fund?.id,
                itemId: data?.itemId,
                jobId: dataJobs?.data?.id,
                body: updBody,
            },
            {
                onSuccess: () => {
                    if (onSuccessUpdate) {
                        onSuccessUpdate();
                    }
                },
            }
        );
    };

    useEffect(() => {
        if (attachmentsData?.data) {
            setSelectedDocuments(attachmentsData.data.filter((a) => data?.comment?.documents?.some((d) => d.attachmentId === a.id)));
        }
    }, [attachmentsData?.data]);

    const commentElRef = useRef<any>();
    const permanentCommentElRef = useRef<any>();
    const externalCommentElRef = useRef<any>();

    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        setDisabled(!isTabEnabled || isUpdateCommentLoading)
    }, [isTabEnabled, isUpdateCommentLoading]);
    
    // This hook is important to trigger Editors' onChange event to apply EditorLink decorators
    useEffect(() => {
        setTimeout(() => {
            externalCommentElRef?.current?.focus();
            permanentCommentElRef?.current?.focus();
            commentElRef?.current?.focus();
            commentElRef?.current?.blur();
        }, 0);
        
        return () => {}
    }, []);
    
    return (
        <Stack tokens={{ childrenGap: 16 }} className={styles}>
            <Stack.Item>
                {data?.standardPoints?.length ? (
                    <StandartPointSection
                        onAddToPermanent={onAddToPermanent}
                        onAddToCurrentYear={onAddToCurrentYear}
                        standardPoints={data.standardPoints}
                        disabledPerm={isPerm || !isTabEnabled}
                    />
                ) : null}
            </Stack.Item>
            {showTransferredAttachments && (
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack tokens={{ childrenGap: 8 }} horizontal horizontalAlign='space-between'>
                        <Text theme={theme.schemes?.default}>{formatMessage({ id: 'workpapersAnnotations' })}</Text>
                        <Checkbox
                            theme={theme.schemes?.default}
                            styles={{
                                root: {
                                    ':hover .ms-Checkbox-text': {
                                        color: theme.schemes?.default?.semanticColors.bodyText,
                                    },
                                },
                                text: {
                                    color: theme.schemes?.default?.semanticColors.bodyText,
                                },
                            }}
                            checked={isExternalHidden ?? false}
                            onChange={(_, checked) => setIsExternalHidden(checked ?? false)}
                            label={formatMessage({ id: 'hide' })}
                        />
                    </Stack>
                    
                    <Stack
                        styles={{
                            root: {
                                border: `1px solid ${theme.semanticColors.disabledBorder}`,
                                borderRadius: 2,
                                overflow: 'hidden',
                                padding: 8,
                                '.public-DraftEditor-content': {
                                    color: theme.schemes?.default?.semanticColors.bodyText,
                                },
                            },
                        }}>
                        <Editor
                            ref={externalCommentElRef}
                            editorState={externalFormState.external}
                            onChange={(editorState: EditorState) => handleOnChange(editorState, 'external')}
                            readOnly={true}
                        />
                    </Stack>
                </Stack>
            )}
            <Stack tokens={{ childrenGap: 8 }}>
                <Checkbox
                    theme={isPerm ? theme : theme.schemes?.default}
                    styles={{
                        root: {
                            ':hover .ms-Checkbox-text': {
                                color: theme.schemes?.default?.semanticColors.bodyText,
                            },
                        },
                        text: {
                            color: theme.schemes?.default?.semanticColors.bodyText,
                        },
                    }}
                    checked={isPerm}
                    disabled={!isTabEnabled}
                    onChange={(_, checked) => setPerm(!!checked)}
                    label={formatMessage({ id: 'permanentCommentForFutureJobs' })}
                />
                <Stack
                    styles={{
                        root: {
                            border: `1px solid ${
                                isPerm ? theme.semanticColors.disabledBorder : theme.schemes?.default?.palette.blackTranslucent40
                            }`,
                            borderRadius: 2,
                            overflow: 'hidden',
                            padding: 8,
                            '.public-DraftEditor-content': {
                                color: theme.schemes?.default?.semanticColors.bodyText,
                            },
                        },
                    }}>
                    <Editor
                        ref={permanentCommentElRef}
                        editorState={formState.permanentComment}
                        onChange={(editorState: EditorState) => handleOnChange(editorState, 'permanentComment')}
                        readOnly={isPerm || disabled}
                    />
                </Stack>
            </Stack>
            {!isAttachmentsLoading ? (
                <JobCommentLinkedDocuments
                    selected={selectedPermDocuments}
                    setSelected={setSelectedPermDocuments}
                    documents={data?.permanentComment?.documents}
                    attachments={attachmentsData?.data.filter(x => x.isPermanent)}
                    isLoading={isAttachmentsLoading}
                    disabled={isPerm || disabled}
                />
            ) : (
                <Spinner />
            )}
            <Stack.Item>
                <Label styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }}>
                    {formatMessage({ id: 'currentYearComment' })}
                </Label>
                <Stack
                    styles={{
                        root: {
                            border: `1px solid ${theme.schemes?.default?.palette.blackTranslucent40}`,
                            borderRadius: 2,
                            overflow: 'hidden',
                            padding: 8,
                            '.public-DraftEditor-content': {
                                color: theme.schemes?.default?.semanticColors.bodyText,
                            },
                        },
                    }}>
                    <Editor
                        ref={commentElRef}
                        editorState={formState.comment}
                        onChange={(editorState: EditorState) => handleOnChange(editorState, 'comment')}
                        readOnly={disabled}
                    />
                </Stack>
            </Stack.Item>
            <JobCommentLinkedDocuments
                selected={selectedDocuments}
                setSelected={setSelectedDocuments}
                documents={data?.comment?.documents}
                attachments={attachmentsData?.data.filter(x => !x.isPermanent)}
                isLoading={isAttachmentsLoading}
                disabled={disabled}
            />
            <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                <PrimaryButton text={formatMessage({ id: 'save' })} onClick={onSave} disabled={disabled} />
                <DefaultButton onClick={() => onCancel()} text={formatMessage({ id: 'cancel' })} />
            </Stack>
        </Stack>
    );
};

const EditorLink = (props?: { decoratedText: string, children: any } & object) => {
    const theme = useTheme();

    const url = useMemo<string>(() => {
        if (!props?.decoratedText) {
            return '';
        }
        if (props.decoratedText.includes('https')) {
            return props.decoratedText;
        } else if (props.decoratedText.includes('http')) {
            return `${props.decoratedText}`.replace('http', 'https');
        } else if (props.decoratedText.includes('www')) {
            return `${props.decoratedText}`.replace('www', 'https://www');
        } else {
            return `https://${props.decoratedText}`;
        }
    }, [props?.decoratedText])

    const onClick = () => {
        window.open(url, '_blank');
    };

    if (!url) {
        return <></>;
    }

    return (
        <span style={{
            color: theme.schemes?.default?.palette.blue,
            textDecoration: 'underline',
            cursor: 'pointer'
        }} onClick={onClick}>{props!.children}</span>
    );
};
