import {useGetJobByGuid} from 'hooks/useGetJobByGuid';
import React, {
    createContext,
    forwardRef,
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import {useGetProcedures} from '../hooks';
import { IProcedure, ISectionInfo, ISectionItemAnswerInfo, ISectionWithAnswersContext } from "../interfaces";
import {useParams} from 'react-router-dom';
import {ControlType} from 'enums/ControlType';
import {
    AdministrationProcedureTemplate,
    AssessmentIdentifiedRiskProcedureTemplate,
    AuditAssertionsProcedureTemplate,
    AuditCompletionLetterTemplate, AuditFeeFormProceudreTemplate,
    AuditQualificationTemplate,
    AuditReadySummaryProcedureContent,
    AuditReadySummarySection,
    AuditTrailProcedureTemplate,
    ClientCommentsProcedureTemplate,
    CollapsableGridProcedureTemplate, ComparativeInformationProcedureTemplate,
    ConclusionOpinionProcedureTemplate,
    ConclusionProcedureTemplate,
    CorresspondanceProcedureTemplate,
    FeeCalculationProcedureTemplate,
    FeeCalculationV2ProcedureTemplate,
    FileNotesProcedureTemplate,
    GeneralQuestionProcedureTemplate,
    GridProcedureTemplate,
    GridWithoutAnswersProcedureTemplate, HINTableProcedureContent,
    HINTableProcedureTemplate,
    MandatoryQuestionProcedureTemplate,
    OverallAuditStrategyProcedureTemplate,
    PrintToPDFProcedureTemplate,
    PropertyDetailsProcedureTemplate,
    PropertyDetailsTextProcedureTemplate,
    QuerySummaryProcedureTemplate,
    RelevantAuditStandardsProcedureTemplate,
    ReviewItemProcedureTemplate,
    ReviewQualificationItemProcedureTemplate,
    RiskCategorizationProcedureTemplate,
    RiskMatrixProcedureTemplate,
    StaticListProcedureTemplate,
    StaticListWithHeaderProcedureTemplate,
    SubsequentEventsProcedureTemplate,
    SuggestedTestingProcedureTemplate,
    TestSummaryProcedureTemplate,
    TestSummaryReviewProcedureTemplate,
    UndersAndOversCalculationProcedureTemplate
} from "./templates/procedures";
import {Loading} from "../../../components";
import {DeletedAuditQueriesTemplate, InitialAuditQueriesTemplate} from "./templates/procedures/AuditQueries";
import { useSectionContext } from './Section';
import { AuditAcceptanceProcedureTemplate } from "./templates/procedures/AuditAcceptance";
import { calculateSectionAnswer } from "./templates/answers";
import { useJobContext } from "../JobPortalLayoutPage";

interface Props {
    section: ISectionInfo;
}

interface IProcedureProps {
    controlType: number;
    childRef: MutableRefObject<null>;
}

interface IProcedureContext extends ISectionWithAnswersContext {
    items: any[];
    tableType?: number;
    isLoading?: boolean;
    refresh: (silent?: boolean) => void;
    data?: any,
}

const ProcedureContext = createContext<IProcedureContext>({
    items: [], refresh: () => {
    },
});

export const useProcedureContext = () => useContext(ProcedureContext);


const Procedure: FunctionComponent<IProcedureProps> = ({controlType, childRef}) => {
    switch (controlType) {
        case ControlType.StaticList:
            return <StaticListProcedureTemplate/>;
        case ControlType.StaticListWithHeader:
            return <StaticListWithHeaderProcedureTemplate/>;
        case ControlType.BankAccount:
        case ControlType.GeneralLedger:
        case ControlType.Grid:
            return <GridProcedureTemplate/>;
        case ControlType.GridWithoutAnswers:
            return <GridWithoutAnswersProcedureTemplate/>;
        case ControlType.AuditAssertions:
            return <AuditAssertionsProcedureTemplate/>;
        case ControlType.RelevantAuditStandards:
            return <RelevantAuditStandardsProcedureTemplate/>;
        case ControlType.ReviewItem:
            return <ReviewItemProcedureTemplate childRef={childRef}/>;
        case ControlType.AuditFeeForm:
            return <AuditFeeFormProceudreTemplate />
        case ControlType.CollapsableGrid:
            return <CollapsableGridProcedureTemplate/>;
        case ControlType.TestSummary:
            return <TestSummaryProcedureTemplate/>;
        case ControlType.TestSummaryReview:
            return <TestSummaryReviewProcedureTemplate/>;
        case ControlType.RiskCategorization:
            return <RiskCategorizationProcedureTemplate/>;
        case ControlType.PropertyDetails:
            return <PropertyDetailsProcedureTemplate/>;
        case ControlType.InitialAudit:
            return <InitialAuditQueriesTemplate/>
        case ControlType.DeletedAudit:
            return <DeletedAuditQueriesTemplate/>;
        case ControlType.HINTable:
            return <HINTableProcedureContent/>;
        case ControlType.ReviewQualificationItem:
            return <ReviewQualificationItemProcedureTemplate ref={childRef}/>;
        case ControlType.MandatoryQuestion:
            return <MandatoryQuestionProcedureTemplate/>;
        case ControlType.GeneralQuestion:
            return <GeneralQuestionProcedureTemplate/>;
        case ControlType.ClientComments:
            return <ClientCommentsProcedureTemplate/>;
        case ControlType.RiskMatrix:
            return <RiskMatrixProcedureTemplate/>;
        case ControlType.AuditTrail:
            return <AuditTrailProcedureTemplate/>;
        case ControlType.OverallAuditStrategy:
            return <OverallAuditStrategyProcedureTemplate/>;
        case ControlType.QuerySummary:
            return <QuerySummaryProcedureTemplate/>;
        case ControlType.Administration:
            return <AdministrationProcedureTemplate/>;
        case ControlType.Corresspondance:
            return <CorresspondanceProcedureTemplate/>;
        case ControlType.AuditAcceptance:
            return <AuditAcceptanceProcedureTemplate/>;
        case ControlType.Conclusion:
            return <ConclusionProcedureTemplate/>;
        case ControlType.ConclusionOpinion:
            return <ConclusionOpinionProcedureTemplate/>;
        case ControlType.FeeCalculation:
            return <FeeCalculationProcedureTemplate/>;
        case ControlType.FeeCalculationV2:
            return <FeeCalculationV2ProcedureTemplate/>;
        case ControlType.FileNotes:
            return <FileNotesProcedureTemplate ref={childRef}/>;
        case ControlType.PropertyDetailsText:
            return <PropertyDetailsTextProcedureTemplate/>;
        case ControlType.AuditReadySummary:
            return <AuditReadySummarySection ref={childRef}/>;
        case ControlType.SuggestedTesting:
            return <SuggestedTestingProcedureTemplate/>;
        case ControlType.PrintToPDF:
            return <PrintToPDFProcedureTemplate/>;
        case ControlType.ComparativeInformation:
            return <ComparativeInformationProcedureTemplate/>;
        case ControlType.SubsequentEvents:
            return <SubsequentEventsProcedureTemplate ref={childRef}/>;
        case ControlType.UndersAndOversCalculation:
            return <UndersAndOversCalculationProcedureTemplate/>;
        case ControlType.AssessmentIdentifiedRisk:
            return <AssessmentIdentifiedRiskProcedureTemplate ref={childRef}/>;
        case ControlType.AuditQualification:
            return <AuditQualificationTemplate/>
        case ControlType.CompletionLetter:
            return <AuditCompletionLetterTemplate ref={childRef}/>
        case ControlType.AuditReadySummarySection:
            return <AuditReadySummaryProcedureContent/>
        default:
            return <div></div>;
    }
};

export const ProceduresContent = forwardRef(({section}: Props, ref) => {
    const {guid} = useParams();
    const {dataJobs} = useGetJobByGuid({guid});
    const {answerOptions} = useJobContext();
    const childRef = useRef<any>(ref);
    const { updateLoadedState, isPositiveSectionAnswer, updateSectionAnswer } = useSectionContext();
    
    const {proceduresData, isProceduresLoading, isProceduresFetching, refetchProcedures} = useGetProcedures({
        templateId: dataJobs?.data?.templateId,
        jobId: dataJobs?.data?.id,
        jobYear: dataJobs?.data?.year,
        sectionId: section.id,
        controlType: section.controlType,
        fundId: dataJobs?.data?.fund?.id,
        tabReference: section.reference ?? ""
    });

    const addHandler = () => {
        if (childRef?.current?.onAddClick) {
            childRef?.current?.onAddClick();
        }
    };

    const [silentRefetch, setSilentRefetch] = useState<boolean>(false);

    const getAnswers = useCallback((procedures: any[]) => {
        let result: ISectionItemAnswerInfo[] = [];
        for (let procedure of procedures) {
            if (section.controlType === ControlType.Conclusion && procedure.items?.length) {
                result = result.concat(getAnswers(procedure.items))
            } else {
                const { id, answerText, actionTaken, isDisabled, hasOmls, hasClearedOmls, hasNotClearedOmls } = procedure;
                result.push({ id, answerText, actionTaken, isDisabled, hasOmls, hasClearedOmls, hasNotClearedOmls });

                if (procedure.children && procedure.children.length > 0) {
                    result = result.concat(getAnswers(procedure.children));
                }
            }
        }
        return result;
    }, [section.controlType])

    const [answers, setAnswers] = useState<Partial<ISectionItemAnswerInfo>[]>([]);
    const updateAnswers = (answers: Partial<ISectionItemAnswerInfo>[]) => setAnswers(answers);
    
    const onItemAnswerChanged = useCallback((newValue: string, itemId?: number) => {
        console.debug("[PROCEDURES::onItemAnswerChanged::initial]", newValue, itemId, answers);
        
        if (!answers?.length || !itemId) {
            return;
        }
        
        const answerIndex = answers.findIndex(x => x.id === itemId);
        if (answerIndex === -1) {
            return;
        }

        const updatedAnswers = [
            ...answers.slice(0, answerIndex),
            {
                ...answers.at(answerIndex),
                answerText: newValue
            },
            ...answers.slice(answerIndex + 1)
        ];
        updateAnswers(updatedAnswers);

        const expression = (newValue: boolean | null): boolean | null => {
            console.debug("[CUSTOM_EXPR]", newValue, updatedAnswers);
            return !!newValue && updatedAnswers.some((a: any) => !!a.hasNotClearedOmls) ? false : newValue;
        }
        const sectionAnswer = calculateSectionAnswer(updatedAnswers, answerOptions, isPositiveSectionAnswer, expression);
        console.debug("[PROCEDURES::onItemAnswerChanged::calculated_answer]", sectionAnswer, updatedAnswers);
        updateSectionAnswer(sectionAnswer);
        
    }, [answers, answerOptions, isPositiveSectionAnswer])

    useEffect(() => {
        setAnswers(proceduresData?.data.items ? getAnswers(proceduresData.data.items) : []);

        updateLoadedState(!(isProceduresLoading || isProceduresFetching));
    }, [proceduresData]);

    useImperativeHandle(ref, () => ({
        refresh() {
            if (section.controlType) {
                refetchProcedures();
            }
        },
        onAddClick() {
            addHandler();
        },
        onApprove(approved: boolean, type: 'manager' | 'partner' | 'sendEmail', update: Function) {
            if (childRef?.current?.onApprove) {
                childRef?.current?.onApprove(approved, type, update);
            } else {
                update(approved, type);
            }
        }
    }));

    if (isProceduresLoading || (isProceduresFetching && !silentRefetch)) return <Loading/>;
    
    return (
        <ProcedureContext.Provider value={{
            items: proceduresData?.data?.items || [],
            tableType: section.tableType,
            isLoading: isProceduresFetching,
            refresh: (silent?: boolean) => { 
                setSilentRefetch(silent ?? false)
                refetchProcedures()
            },
            answers: answers,
            updateAnswers: updateAnswers,
            data: proceduresData?.data,
            
            onItemAnswerChanged: onItemAnswerChanged
        }}>
            <Procedure childRef={childRef} controlType={section?.controlType}/>
        </ProcedureContext.Provider>
    );
});