import {AnswerControlType, SoftwareType, TableType} from "../../../../../../enums";
import React, {FunctionComponent, useCallback, useMemo, useRef} from "react";
import {useIntl} from "react-intl";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {IColumn, IDropdownOption, Stack, Text} from "@fluentui/react";
import {ProceduresDataTable} from "../../../ProceduresDataTable";
import {JobOmls} from "../../../OmlComponent";
import {JobComment} from "../../../JobComment";
import {DropdownAnswer} from "../../answers";
import {ConclusionReportItem} from "./interfaces/ConclusionReportItem";
import {ITableColumn, SanitizedText} from "../../../../../../components";
import {getDepthPaddingStyles} from "../../reports/Table/consts";
import {ColumnInfo} from "../../../../enums";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {RedirectButton} from "../../shared";
import {useUpdateItemAnswer} from "../../../../hooks/answers";
import {formatDate} from "../../../../../../utils";
import {NotificationType, useSignalR} from "../../../../../../providers";
import {useTabContext} from "../../../../JobPortalPage";
import {useSectionContext} from "../../../Section";

type ConclusionReportProps = {
    tableType: TableType,
    items: ConclusionReportItem[],
}

export const ConclusionReportTemplate: FunctionComponent<ConclusionReportProps> = ({
                                                                                       tableType,
                                                                                       items,
                                                                                   }: ConclusionReportProps) => {
    const {formatMessage} = useIntl();
    const {job, jobId} = useJobContext()

    const {isTabEnabled} = useTabContext();
    const {refresh, open, isOpened, section} = useSectionContext();

    const {update} = useUpdateItemAnswer();
    const {useSignalREffect} = useSignalR();

    const actionTakenAnswerRefs = useRef<any>([]);

    const actionTakenOptions = useMemo<IDropdownOption[]>(() => {
        const values = ['', 'Cleared', 'W Query Raised', 'NW Query Raised', 'Query Cleared', 'Contravention', 'Qualification', 'Resolved', 'ACL'];

        return values.map(x => ({key: x, text: x}));
    }, []);

    const dropdownOptions = useMemo<IDropdownOption[]>(() => {
        const values = ['', 'Agreed', 'Not Agreed', 'Audit Completion Letter', 'Resolved', 'Cleared'];

        return values.map(x => ({key: x, text: x}));
    }, []);

    useSignalREffect("answer_actiontaken_set", (notification: NotificationType) => {
        const {itemId, change, jobId: notificationJobId} = notification.content;
        const index = (items ?? []).findIndex(i => i.raw.id === itemId);

        if (index !== -1 && notificationJobId === jobId) {
            actionTakenAnswerRefs.current[index].setNewValue(change.NewValue);
        }
    }, [formatMessage]);

    useSignalREffect("answer_answertext_set", (notification: NotificationType) => {
        const {itemId, jobId: notificationJobId} = notification.content;

        const index = (items ?? []).findIndex(i => i.raw.id === itemId);

        if (index !== -1 && notificationJobId === jobId) {
            console.log('answer_answertext_set:section', section);
            if (isOpened)
                refresh();
            else open();
        }
    }, [refresh]);

    const handleOnResultChange = useCallback((newValue: string | null, item: any, index: number) => {
        update({
            jobId: jobId,
            itemId: item.id,
            tableType: tableType,
            columnInfo: ColumnInfo.ClearedText,
            text: newValue
        });
    }, [update, jobId, tableType]);

    const getReportColumns = useCallback((softwareType: SoftwareType): (tableType: TableType) => ITableColumn[] => {
        type ReviewedColumnControlConfig = {
            id?: any,
            options?: IDropdownOption[],
            columnInfo?: ColumnInfo,
            valuePropName?: string;
            defaultValue?: string;
            disabled?: boolean;
        }

        type ReviewedColumnProps = {
            key?: string;
            nameId?: string,
            controlConfig?: (item: any) => ReviewedColumnControlConfig;
        }

        const _getReviewedColumn = ({...props}: ReviewedColumnProps): ITableColumn => {
            return {
                key: props.key ?? 'reviewed' + Date.now(),
                name: formatMessage({id: props.nameId ?? 'reviewed'}),
                minWidth: 180,
                maxWidth: 180,
                fieldName: props.nameId ?? 'reviewed',
                onRender: (item: any, _?: number, column?: IColumn) => {
                    const config: ReviewedColumnControlConfig = {
                        id: item?.id,
                        options: dropdownOptions,
                        columnInfo: ColumnInfo.AnswerText,
                        valuePropName: 'clearedText',
                        defaultValue: 'Not Agreed',
                        disabled: false,
                        ...props.controlConfig?.(item)
                    }

                    return (
                        <Stack verticalAlign='center' grow>
                            <GeneratedAnswer
                                columnInfo={config.columnInfo}
                                itemId={config.id}
                                tableType={tableType}
                                disabled={config.disabled}
                                options={config.options}
                                value={config.valuePropName ? item[config.valuePropName] : config.defaultValue}
                                answerControlType={AnswerControlType.Dropdown}
                                onChange={(newValue) => handleOnResultChange(newValue, item, _!)}
                                styles={{root: {maxWidth: column?.minWidth}}}
                            />
                        </Stack>
                    );
                }
            }
        }

        const _getClassSuperReportColumns = (tableType: TableType): ITableColumn[] => {
            switch (tableType) {
                case TableType.ClassSuperStatmentofFP:
                    return [{
                        key: 'description',
                        name: formatMessage({id: 'description'}),
                        minWidth: 300,
                        fieldName: 'description',
                        onRender: (item, _, column) => (
                            <SanitizedText data={item[column?.fieldName || 0]}
                                           styles={getDepthPaddingStyles(item?.Depth)} format={item?.format}/>
                        )
                    },
                        {
                            key: 'fundNotesReferenceNumber',
                            name: formatMessage({id: 'fundNotesReferenceNumber'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'fundNotesReferenceNumber',
                            onRender: (item, _, column) => (
                                <SanitizedText data={item[column?.fieldName || 0]} format={item?.format}
                                               textAlign='center'/>
                            )
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'balance',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'balance',
                            onRender: (item, _, column) => (
                                <SanitizedText data={item[column?.fieldName || 0]} format={item?.format}
                                               textAlign='right' numberFormat='fractional'/>
                            )
                        },
                        {
                            key: 'previousYearBalance',
                            name: formatMessage({id: 'previousYearBalance'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'previousYearBalance',
                            onRender: (item, _, column) => (
                                <SanitizedText data={item[column?.fieldName || 0]}
                                               format={item?.format} textAlign='right' numberFormat='fractional'/>
                            )
                        },
                        {
                            key: 'change',
                            name: formatMessage({id: 'change'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => (
                                <SanitizedText
                                    data={((item['balance'] || 0) - (item['previousYearBalance'] || 0)).toString()}
                                    format={item?.format} textAlign='right' numberFormat='fractional'/>
                            )
                        },
                        {
                            key: 'pChange',
                            name: formatMessage({id: 'pChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'pChange',
                            onRender: (item, _, column) => {
                                const value = ((item['balance'] || 0) - (item['previousYearBalance'] || 0)) / (item['previousYearBalance'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            }
                        }]
                case TableType.ClassSuperOperatingStatement:
                    return [{
                        key: 'description',
                        name: formatMessage({id: 'description'}),
                        minWidth: 300,
                        fieldName: 'description',
                        onRender: (item, _, column) => (
                            <SanitizedText data={item[column?.fieldName || 0]}
                                           styles={getDepthPaddingStyles(item?.Depth)} format={item?.format}/>
                        )
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'balance',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'balance',
                            onRender: (item, _, column) => (
                                <SanitizedText data={item[column?.fieldName || 0]} format={item?.format}
                                               textAlign='right' numberFormat='fractional'/>
                            )
                        },
                        {
                            key: 'previousYearBalance',
                            name: formatMessage({id: 'previousYearBalance'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'previousYearBalance',
                            onRender: (item, _, column) => (
                                <SanitizedText data={item[column?.fieldName || 0]}
                                               format={item?.format} textAlign='right' numberFormat='fractional'/>
                            )
                        },
                        {
                            key: 'change',
                            name: formatMessage({id: 'change'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => (
                                <SanitizedText
                                    data={((item['balance'] || 0) - (item['previousYearBalance'] || 0)).toString()}
                                    format={item?.format} textAlign='right' numberFormat='fractional'/>
                            )
                        },
                        {
                            key: 'pChange',
                            name: formatMessage({id: 'pChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'pChange',
                            onRender: (item, _, column) => {
                                const value = ((item['balance'] || 0) - (item['previousYearBalance'] || 0)) / (item['previousYearBalance'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            }
                        }]
                case TableType.ClassSuperInvestmentMovement:
                    return [
                        {
                            key: 'investmentName',
                            name: formatMessage({id: 'description'}),
                            minWidth: 400,
                            fieldName: 'investmentName',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'openingQuantity',
                            name: formatMessage({id: 'obQty'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'openingQuantity',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='round'/>,
                        },
                        {
                            key: 'openingBookCost',
                            name: formatMessage({id: 'obCost'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'openingBookCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'totalAcquisitionQuantity',
                            name: formatMessage({id: 'acquiQty'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'totalAcquisitionQuantity',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='round'/>,
                        },
                        {
                            key: 'totalAcquisitionCost',
                            name: formatMessage({id: 'acquiCost'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'totalAcquisitionCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'totalDisposalQuantity',
                            name: formatMessage({id: 'dispQty'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'totalDisposalQuantity',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='round'/>,
                        },
                        {
                            key: 'totalDisposalProceeds',
                            name: formatMessage({id: 'dispProceeds'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'totalDisposalProceeds',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'profitOrLoss',
                            name: formatMessage({id: 'dispProfitLoss'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'profitOrLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingQuantity',
                            name: formatMessage({id: 'cbQty'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'closingQuantity',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='round'/>,
                        },
                        {
                            key: 'closingBookCost',
                            name: formatMessage({id: 'cbCost'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'closingBookCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingMarketValue',
                            name: formatMessage({id: 'cbMarketValue'}),
                            minWidth: 120,
                            maxWidth: 180,
                            fieldName: 'closingMarketValue',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                case TableType.ClassSuperInvestmentSummary:
                    return [{
                        key: 'holdingAccountName',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 200,
                        fieldName: 'holdingAccountName',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'asx',
                            name: formatMessage({id: 'asx'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'asx',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'units',
                            name: formatMessage({id: 'units'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'units',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'averageCost',
                            name: formatMessage({id: 'averageCostPrice'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'averageCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText1,
                                disabled: item.isAuto1,
                                valuePropName: item.isAuto1 ? 'answerText1' : 'clearedText1',
                                options: item.isAuto1 ? [...dropdownOptions, {
                                    key: 'Auto',
                                    text: 'Auto'
                                }] : dropdownOptions
                            })
                        }),
                        {
                            key: 'marketPrice',
                            name: formatMessage({id: 'marketPrice'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'marketPrice',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'cost',
                            name: formatMessage({id: 'cost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'cost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText2,
                                disabled: item.isAuto2,
                                valuePropName: item.isAuto2 ? 'answerText2' : 'clearedText2',
                                options: item.isAuto2 ? [...dropdownOptions, {
                                    key: 'Auto',
                                    text: 'Auto'
                                }] : dropdownOptions
                            })
                        }),
                        {
                            key: 'market',
                            name: formatMessage({id: 'marketValue'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'market',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'unrealisedGain',
                            name: formatMessage({id: 'unrealisedGain'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'unrealisedGain',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'gainPercentage',
                            name: formatMessage({id: 'gainPercentage'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'gainPercentage',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right'
                                                                          numberFormat='percentage-fractional'/>,
                        },
                        {
                            key: 'totalPercentage',
                            name: formatMessage({id: 'totalPercentage'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'totalPercentage',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right'
                                                                          numberFormat='percentage-fractional'/>,
                        }]
                case TableType.ClassSuperRealisedCapitalGainsReport:
                    return [{
                        key: 'date',
                        name: '',
                        minWidth: 100,
                        maxWidth: 100,
                        fieldName: 'date',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      styles={getDepthPaddingStyles(item?.depth)}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'capitalEventType',
                            name: '',
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capitalEventType',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'quantity',
                            name: formatMessage({id: 'quantity'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'quantity',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'proceeds',
                            name: formatMessage({id: 'atProceeds'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'proceeds',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'bookCost',
                            name: formatMessage({id: 'atOriginalCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'bookCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'profitLoss',
                            name: formatMessage({id: 'atAccountingProfitLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'profitLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'adjustedCostBase',
                            name: formatMessage({id: 'adjCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'adjustedCostBase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'reducedCostBase',
                            name: formatMessage({id: 'reducedCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'reducedCostBase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'indexedCostBase',
                            name: formatMessage({id: 'indexedCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'indexedCostBase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'indexedGain',
                            name: formatMessage({id: 'capitalGainIndexation'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'indexedGain',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'discountableGain',
                            name: formatMessage({id: 'capitalDiscountableGain'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'discountableGain',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'otherGain',
                            name: formatMessage({id: 'capitalOtherGain'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'otherGain',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'capitalLoss',
                            name: formatMessage({id: 'capitalLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capitalLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          format={item?.format}
                                                                          textAlign='right' numberFormat='fractional'/>,
                        }]
                case TableType.ClassSuperHoldingsBalanceReconciliation:
                    return [{
                        key: 'holdingAccount',
                        name: formatMessage({id: 'holdingsAccount'}),
                        minWidth: 250,
                        fieldName: 'holdingAccount',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'fundHoldingReference',
                            name: formatMessage({id: 'fundHoldingReference'}),
                            minWidth: 200,
                            maxWidth: 220,
                            fieldName: 'fundHoldingReference',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'marketType',
                            name: formatMessage({id: 'marketType'}),
                            minWidth: 200,
                            maxWidth: 220,
                            fieldName: 'marketType',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'ledgerBalance',
                            name: formatMessage({id: 'ledgerBalance'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'ledgerBalance',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='default'/>,
                        },
                        {
                            key: 'feedBalance',
                            name: formatMessage({id: 'feedBalance'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'feedBalance',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='default'/>,
                        },
                        {
                            key: 'difference',
                            name: formatMessage({id: 'difference'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'difference',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='default'/>,
                        }]
                case TableType.ClassSuperInvestmentIncome: {
                    return [{
                        key: 'markenName',
                        name: formatMessage({id: 'marketName'}),
                        minWidth: 200,
                        maxWidth: 300,
                        fieldName: 'marketName',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    }, {
                        key: 'investmentName',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 200,
                        maxWidth: 300,
                        fieldName: 'investmentName',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      styles={getDepthPaddingStyles(item?.depth)}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'incomeTotal',
                            name: formatMessage({id: 'incomeTotal'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'incomeTotal',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'frankingCredits',
                            name: formatMessage({id: 'frankingCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'frankingCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'foreignCredits',
                            name: formatMessage({id: 'foreignCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'foreignCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'tfnCredits',
                            name: formatMessage({id: 'tfnCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'tfnCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxFree',
                            name: formatMessage({id: 'taxFree'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxFree',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxExempt',
                            name: formatMessage({id: 'taxExempt'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxExempt',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxDeferred',
                            name: formatMessage({id: 'taxDeferred'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxDeferred',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'distributedCapitalGains',
                            name: formatMessage({id: 'distributedCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'distributedCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'gst',
                            name: formatMessage({id: 'gst'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'gst',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxableIncomeExclCapitalGains',
                            name: formatMessage({id: 'taxableIncomeExclCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxableIncomeExclCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'capitalGainsIndexed',
                            name: formatMessage({id: 'capitalGainsIndexed'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capitalGainsIndexed',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'capitalGainsDiscounted',
                            name: formatMessage({id: 'capitalGainsDiscounted'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capitalGainsDiscounted',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'capitalGainsOther',
                            name: formatMessage({id: 'capitalGainsOther'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capitalGainsOther',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'capitalGainsCgtConcession',
                            name: formatMessage({id: 'capitalGainsCGTConcession'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capitalGainsCgtConcession',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }
                    ]
                }
                default: {
                    return []
                }
            }
        }

        const _getBgl360ReportColumns = (tableType: TableType): ITableColumn[] => {
            switch (tableType) {
                case TableType.BGL360FinancialPosition: {
                    return [{
                        key: 'name',
                        name: '',
                        minWidth: 500,
                        maxWidth: 500,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      styles={getDepthPaddingStyles(item?.depth)}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'to',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'to',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'from',
                            name: formatMessage({id: 'lastYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'from',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'change',
                            name: formatMessage({id: 'moneyChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => <SanitizedText
                                data={((item['to'] || 0) - (item['from'] || 0)).toString()}
                                format={item?.format} textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'percentChange',
                            name: formatMessage({id: 'percentChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentChange',
                            onRender: (item, _, column) => {
                                const value = ((item['to'] || 0) - (item['from'] || 0)) / (item['from'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            },
                        }
                    ]
                }
                case TableType.BGL360OperatingStatement: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'description'}),
                        minWidth: 500,
                        maxWidth: 500,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'to',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'to',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'from',
                            name: formatMessage({id: 'lastYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'from',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'change',
                            name: formatMessage({id: 'moneyChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => <SanitizedText
                                data={((item['to'] || 0) - (item['from'] || 0)).toString()}
                                format={item?.format} textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'percentChange',
                            name: formatMessage({id: 'percentChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentChange',
                            onRender: (item, _, column) => {
                                const value = ((item['to'] || 0) - (item['from'] || 0)) / (item['from'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            },
                        }]
                }
                case TableType.BGL360InvestmentMovement: {
                    return [{
                        key: 'investment',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 400,
                        maxWidth: 400,
                        fieldName: 'investment',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      styles={getDepthPaddingStyles(item?.depth)}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'openingBalanceUnits',
                            name: formatMessage({id: 'obUnit'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'openingBalanceUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          textAlign='right' format={item?.format}/>,
                        },
                        {
                            key: 'openingBalanceCost',
                            name: formatMessage({id: 'obCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'openingBalanceCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'additionsUnits',
                            name: formatMessage({id: 'Additions Units'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'additionsUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'/>,
                        },
                        {
                            key: 'additionsCost',
                            name: formatMessage({id: 'cost'}),
                            minWidth: 80,
                            maxWidth: 128,
                            fieldName: 'additionsCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'disposalsUnits',
                            name: formatMessage({id: 'disposalUnits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'disposalsUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'/>,
                        },
                        {
                            key: 'disposalCost',
                            name: formatMessage({id: 'disposalCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'disposalCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'disposalsAccounting',
                            name: formatMessage({id: 'profitLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'disposalsAccounting',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingBalanceUnits',
                            name: formatMessage({id: 'cbUnits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closingBalanceUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'/>,
                        },
                        {
                            key: 'closingBalanceCost',
                            name: formatMessage({id: 'cbCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closingBalanceCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingBalanceMarketValue',
                            name: formatMessage({id: 'market'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closingBalanceMarketValue',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                }
                case TableType.BGL360InvestmentSummary: {
                    return [{
                        key: 'investment',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 300,
                        maxWidth: 300,
                        fieldName: 'investment',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      styles={getDepthPaddingStyles(item?.depth)}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'asx',
                            name: formatMessage({id: 'asx'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'asx',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'units',
                            name: formatMessage({id: 'units'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'units',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          textAlign='right' format={item?.format}/>,
                        },
                        {
                            key: 'aveCost',
                            name: formatMessage({id: 'aveCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'aveCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText1
                            })
                        }),
                        {
                            key: 'mktPrice',
                            name: formatMessage({id: 'mktPrice'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'mktPrice',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cost',
                            name: formatMessage({id: 'cost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'cost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText2
                            })
                        }),
                        {
                            key: 'marketGainLoss',
                            name: formatMessage({id: 'market'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'marketGainLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'unrealisedGainLoss',
                            name: formatMessage({id: 'unrealisedGainLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'unrealisedGainLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'percent',
                            name: formatMessage({id: 'percentGainLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percent',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='percentage'/>,
                        },
                        {
                            key: 'percentTotal',
                            name: formatMessage({id: 'portfolioWeight'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentTotal',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='percentage'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText3
                            })
                        })
                    ]
                }
                case TableType.BGL360RealisedCapitalGains: {
                    return [{
                        key: 'accountingTreatmentPurchaseContractDate',
                        name: formatMessage({id: 'purchaseContractDate'}),
                        minWidth: 100,
                        maxWidth: 128,
                        fieldName: 'accountingTreatmentPurchaseContractDate',
                        onRender: (item, _, column) => {
                            return <SanitizedText data={formatDate(item[column?.fieldName || 0])}
                                                  isHeadingRow={item?.isHeadingRow} format={item?.format}/>
                        }
                    },
                        {
                            key: 'accountingTreatmentDisposalContractDate',
                            name: formatMessage({id: 'disposalContractDate'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'accountingTreatmentDisposalContractDate',
                            onRender: (item, _, column) => <SanitizedText
                                data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow}
                                format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'accountingTreatmentUnits',
                            name: formatMessage({id: 'atUnits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'accountingTreatmentUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'accountingTreatmentCost',
                            name: formatMessage({id: 'atCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'accountingTreatmentCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'accountingTreatmentProceeds',
                            name: formatMessage({id: 'atProceeds'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'accountingTreatmentProceeds',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'accountingTreatmentAccountingProfitLoss',
                            name: formatMessage({id: 'atAccountingProfitLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'accountingTreatmentAccountingProfitLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentAdjustedCostBase',
                            name: formatMessage({id: 'adjCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentAdjustedCostBase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentReducedCostBase',
                            name: formatMessage({id: 'reducedCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentReducedCostBase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentIndexedCostBase',
                            name: formatMessage({id: 'indexedCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentIndexedCostBase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentIndexedGains',
                            name: formatMessage({id: 'indexedGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentIndexedGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentDiscountedGainsGross',
                            name: formatMessage({id: 'discountedGainsGross'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentDiscountedGainsGross',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentOtherGains',
                            name: formatMessage({id: 'otherGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentOtherGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxTreatmentCapitalLoss',
                            name: formatMessage({id: 'capitalLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxTreatmentCapitalLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                }
                case TableType.BGL360InvestmentIncome: {
                    return [{
                        key: 'investment',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 400,
                        maxWidth: 400,
                        fieldName: 'investment',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      styles={getDepthPaddingStyles(item?.depth)}
                                                                      isHeadingRow={item?.isHeadingRow}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'totalIncome',
                            name: formatMessage({id: 'totalIncome'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'totalIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'franked',
                            name: formatMessage({id: 'Franked'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'franked',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'unfranked',
                            name: formatMessage({id: 'Unfranked'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'unfranked',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'interest',
                            name: formatMessage({id: 'Interest'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'interest',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'frankingCredits',
                            name: formatMessage({id: 'frankingCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'frankingCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'foreignIncome',
                            name: formatMessage({id: 'foreignIncome'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'foreignIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'foreignCredits',
                            name: formatMessage({id: 'foreignCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'foreignCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'assessableIncome',
                            name: formatMessage({id: 'assessableIncomeExclCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'assessableIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'tfn',
                            name: formatMessage({id: 'tfnCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'tfn',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'deductions',
                            name: formatMessage({id: 'otherDeductions'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'deductions',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'distributedCapitalGains',
                            name: formatMessage({id: 'distributedCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'distributedCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'nonAssessablePayments',
                            name: formatMessage({id: 'nonAssessablePayments'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'nonAssessablePayments',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                }
                default: {
                    return []
                }
            }
        }

        const _getBglReportColumns = (tableType: TableType): ITableColumn[] => {
            switch (tableType) {
                case TableType.BGLFinancialPosition: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'name'}),
                        minWidth: 300,
                        maxWidth: 400,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'to',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'to',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'from',
                            name: formatMessage({id: 'lastYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'from',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'change',
                            name: '$ ' + formatMessage({id: 'change'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => <SanitizedText
                                data={((item['to'] || 0) - (item['from'] || 0)).toString()}
                                format={item?.format} textAlign='right' numberFormat='fractional'/>
                        },
                        {
                            key: 'percentChange',
                            name: formatMessage({id: 'percentChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentChange',
                            onRender: (item, _, column) => {
                                const value = ((item['to'] || 0) - (item['from'] || 0)) / (item['from'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            },
                        }]
                }
                case TableType.BGLOperating: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'name'}),
                        minWidth: 300,
                        maxWidth: 400,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'to',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'to',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'from',
                            name: formatMessage({id: 'lastYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'from',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'change',
                            name: '$ ' + formatMessage({id: 'change'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => <SanitizedText
                                data={((item['to'] || 0) - (item['from'] || 0)).toString()}
                                format={item?.format} textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'percentChange',
                            name: formatMessage({id: 'percentChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentChange',
                            onRender: (item, _, column) => {
                                const value = ((item['to'] || 0) - (item['from'] || 0)) / (item['from'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            },
                        }]
                }
                case TableType.BGLInvestmentMovement: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 300,
                        maxWidth: 400,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'openingBalanceUnits',
                            name: formatMessage({id: 'obUnit'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'openingBalanceUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'/>,
                        },
                        {
                            key: 'openingBalanceCost',
                            name: formatMessage({id: 'obCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'openingBalanceCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'additionsUnits',
                            name: formatMessage({id: 'additionsUnits'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'additionsUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'additionsCost',
                            name: formatMessage({id: 'cost'}),
                            minWidth: 80,
                            maxWidth: 128,
                            fieldName: 'additionsCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'disposalsUnits',
                            name: formatMessage({id: 'dispUnits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'disposalsUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'disposalsCost',
                            name: formatMessage({id: 'disposalCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'disposalsCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'profLoss',
                            name: formatMessage({id: 'profitLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'profLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingBalanceUnits',
                            name: formatMessage({id: 'cbUnits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closingBalanceUnits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingBalanceCost',
                            name: formatMessage({id: 'cbCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closingBalanceCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'market',
                            name: formatMessage({id: 'market'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'market',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                }
                case TableType.BGLInvestmentSummary: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'investmentName'}),
                        minWidth: 300,
                        maxWidth: 400,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'asx',
                            name: formatMessage({id: 'asx'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'asx',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText
                            })
                        }),
                        {
                            key: 'units',
                            name: formatMessage({id: 'units'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'units',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'/>,
                        },
                        {
                            key: 'aveCost',
                            name: formatMessage({id: 'aveCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'aveCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText1
                            })
                        }),
                        {
                            key: 'mktPrice',
                            name: formatMessage({id: 'mktPrice'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'mktPrice',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cost',
                            name: formatMessage({id: 'cost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'cost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText2
                            })
                        }),
                        {
                            key: 'marketGainLoss',
                            name: formatMessage({id: 'market'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'marketGainLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'unrealisedGainLoss',
                            name: formatMessage({id: 'unrealisedGainLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'unrealisedGainLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'percent',
                            name: formatMessage({id: 'percentGainLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percent',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='percentage-fractional'/>,
                        },
                        {
                            key: 'percentTotal',
                            name: formatMessage({id: 'portfolioWeight'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentTotal',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='percentage-fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText3
                            })
                        })
                    ]
                }
                case TableType.BGLInvestmentDisposal: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'investment'}),
                        minWidth: 200,
                        maxWidth: 256,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'disposalMethod',
                            name: formatMessage({id: 'disposalMethod'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'disposalMethod',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'unitsSold',
                            name: formatMessage({id: 'unitsSold'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'unitsSold',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'purchaseCost',
                            name: formatMessage({id: 'purchaseCost'}),
                            minWidth: 80,
                            maxWidth: 128,
                            fieldName: 'purchaseCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'costBaseAdjustments',
                            name: formatMessage({id: 'costBaseAdjustments'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'costBaseAdjustments',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'adjustedCostBase',
                            name: formatMessage({id: 'adjustedCostBase'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'adjustedCostBase',
                            onRender: (item, _, column) => <SanitizedText
                                data={item.valueType !== 'Heading' ? item[column?.fieldName || 0] : null}
                                isHeadingRow={item?.isBold} format={item?.format} textAlign='right'
                                numberFormat='fractional'/>,
                        },
                        {
                            key: 'consideration',
                            name: formatMessage({id: 'consideration'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'consideration',
                            onRender: (item, _, column) => <SanitizedText
                                data={item.valueType !== 'Heading' ? item[column?.fieldName || 0] : null}
                                isHeadingRow={item?.isBold} format={item?.format} textAlign='right'
                                numberFormat='fractional'/>,
                        },
                        {
                            key: 'totalProfLoss',
                            name: formatMessage({id: 'totalProfLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'totalProfLoss',
                            onRender: (item, _, column) => <SanitizedText
                                data={item.valueType !== 'Heading' ? item[column?.fieldName || 0] : null}
                                isHeadingRow={item?.isBold} format={item?.format} textAlign='right'
                                numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxableProfLoss',
                            name: formatMessage({id: 'taxableProfLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxableProfLoss',
                            onRender: (item, _, column) => <SanitizedText
                                data={item.valueType !== 'Heading' ? item[column?.fieldName || 0] : null}
                                isHeadingRow={item?.isBold} format={item?.format} textAlign='right'
                                numberFormat='fractional'/>,
                        },
                        {
                            key: 'nonTaxableProfLoss',
                            name: formatMessage({id: 'nonTaxableProfLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'nonTaxableProfLoss',
                            onRender: (item, _, column) => <SanitizedText
                                data={item.valueType !== 'Heading' ? item[column?.fieldName || 0] : null}
                                isHeadingRow={item?.isBold} format={item?.format} textAlign='right'
                                numberFormat='fractional'/>,
                        },
                        {
                            key: 'accountingProfLoss',
                            name: formatMessage({id: 'accountingProfLoss'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'accountingProfLoss',
                            onRender: (item, _, column) => <SanitizedText
                                data={item.valueType !== 'Heading' ? item[column?.fieldName || 0] : null}
                                isHeadingRow={item?.isBold} format={item?.format} textAlign='right'/>,
                        }]
                }
                case TableType.BGLInvestmentIncome: {
                    return [{
                        key: 'name',
                        name: formatMessage({id: 'name'}),
                        minWidth: 300,
                        maxWidth: 400,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'totalIncome',
                            name: formatMessage({id: 'incomeTotal'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'totalIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'frankingCredits',
                            name: formatMessage({id: 'frankingCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'frankingCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'addForeignCredits',
                            name: formatMessage({id: 'addForeignCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'addForeignCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'tfnCredits',
                            name: formatMessage({id: 'tfnCredits'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'tfnCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxFree',
                            name: formatMessage({id: 'taxFree'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxFree',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxExempt',
                            name: formatMessage({id: 'taxExempt'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxExempt',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxDeferred',
                            name: formatMessage({id: 'taxDeferred'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxDeferred',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'lessTaxDeferred',
                            name: formatMessage({id: 'lessTaxDeferred'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'lessTaxDeferred',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'expensesGainsDiscount',
                            name: formatMessage({id: 'expenses'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'expensesGainsDiscount',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'capital',
                            name: formatMessage({id: 'capitalGainsDiscount'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'capital',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'gst',
                            name: formatMessage({id: 'gst'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'gst',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxableIncome',
                            name: formatMessage({id: 'taxableIncomeIncCapGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxableIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'indexedCapitalGains',
                            name: formatMessage({id: 'indexedCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'indexedCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'discountedCapitalGains',
                            name: formatMessage({id: 'discountedCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'discountedCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'otherCapitalGains',
                            name: formatMessage({id: 'otherCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'otherCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxableCapitalGains',
                            name: formatMessage({id: 'taxableCapitalGains'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'taxableCapitalGains',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                }
                default: {
                    return []
                }
            }
        }

        const _getSupermateReportColumns = (tableType: TableType): ITableColumn[] => {
            switch (tableType) {
                case TableType.SupermateFinancialPosition: {
                    return [{
                        key: 'name',
                        name: '',
                        minWidth: 200,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'to',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'to',
                            onRender: (item, _, column) => <SanitizedText
                                data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]}
                                isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format}
                                textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'from',
                            name: formatMessage({id: 'lastYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'from',
                            onRender: (item, _, column) => <SanitizedText
                                data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]}
                                isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format}
                                textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'change',
                            name: formatMessage({id: 'moneyChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => <SanitizedText
                                data={((item['to'] || 0) - (item['from'] || 0)).toString()}
                                format={item?.format} textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'percentChange',
                            name: formatMessage({id: 'percentChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentChange',
                            onRender: (item, _, column) => {
                                const value = ((item['to'] || 0) - (item['from'] || 0)) / (item['from'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            },
                        }]
                }
                case TableType.SupermateOperating: {
                    return [{
                        key: 'name',
                        name: '',
                        minWidth: 300,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        _getReviewedColumn({}),
                        {
                            key: 'to',
                            name: formatMessage({id: 'thisYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'to',
                            onRender: (item, _, column) => <SanitizedText
                                data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]}
                                isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format}
                                textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'from',
                            name: formatMessage({id: 'lastYear'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'from',
                            onRender: (item, _, column) => <SanitizedText
                                data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]}
                                isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format}
                                textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'change',
                            name: formatMessage({id: 'moneyChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'change',
                            onRender: (item, _, column) => <SanitizedText
                                data={((item['to'] || 0) - (item['from'] || 0)).toString()}
                                format={item?.format} textAlign='right' numberFormat='fractional'/>,
                        },
                        {
                            key: 'percentChange',
                            name: formatMessage({id: 'percentChange'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'percentChange',
                            onRender: (item, _, column) => {
                                const value = ((item['to'] || 0) - (item['from'] || 0)) / (item['from'] || 0)
                                return <SanitizedText
                                    data={!isFinite(value) ? '' : value.toString()}
                                    format={item?.format} textAlign='right' numberFormat='percentage'/>
                            },
                        }]
                }
                case TableType.SupermateInvestmentMovement: {
                    return [{
                        key: 'accountNumber',
                        name: formatMessage({id: 'accountNumber'}),
                        minWidth: 100,
                        maxWidth: 100,
                        fieldName: 'accountNumber',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'name',
                            name: formatMessage({id: 'accountDescription'}),
                            minWidth: 240,
                            fieldName: 'name',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'openingBalance',
                            name: formatMessage({id: 'openingBalance'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'openingBalance',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'purchase',
                            name: formatMessage({id: 'purchases'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'purchase',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'sale',
                            name: formatMessage({id: 'sales'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'sale',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'adjustment',
                            name: formatMessage({id: 'adjustments'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'adjustment',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closingMarketValue',
                            name: formatMessage({id: 'closingMarketValue'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'closingMarketValue',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'realisedMovement',
                            name: formatMessage({id: 'realisedMovement'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'realisedMovement',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'unrealisedMovement',
                            name: formatMessage({id: 'unrealisedMovement'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'unrealisedMovement',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }]
                }
                case TableType.SupermateInvestmentSummary: {
                    return [{
                        key: 'name',
                        name: '',
                        minWidth: 200,
                        fieldName: 'name',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'code',
                            name: formatMessage({id: 'asx'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'code',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'closingUnit',
                            name: formatMessage({id: 'units'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closingUnit',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'averageUnitCost',
                            name: formatMessage({id: 'averageUnitCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'averageUnitCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText1,
                                disabled: item.isAuto1,
                                valuePropName: item.isAuto1 ? 'answerText1' : 'clearedText1',
                                options: item.isAuto1 ? [...dropdownOptions, {
                                    key: 'Auto',
                                    text: 'Auto'
                                }] : dropdownOptions
                            })
                        }),
                        {
                            key: 'marketPrice',
                            name: formatMessage({id: 'mktPrice'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'marketPrice',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'actualCost',
                            name: formatMessage({id: 'adjustedCost'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'actualCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        _getReviewedColumn({
                            controlConfig: (item: any) => ({
                                columnInfo: ColumnInfo.AnswerText2,
                                disabled: item.isAuto2,
                                valuePropName: item.isAuto2 ? 'answerText2' : 'clearedText2',
                                options: item.isAuto2 ? [...dropdownOptions, {
                                    key: 'Auto',
                                    text: 'Auto'
                                }] : dropdownOptions
                            })
                        }),
                        {
                            key: 'marketValue',
                            name: formatMessage({id: 'marketValue'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'marketValue',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'netMarket',
                            name: formatMessage({id: 'gainLoss'}) + ' ($)',
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'netMarket',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'gainLossPer',
                            name: formatMessage({id: 'gainPercentage'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'gainLossPer',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'closePer',
                            name: formatMessage({id: 'totalPercentage'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'closePer',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        }
                    ]
                }
                case TableType.SupermateRealisedCapitalGains: {
                    return [{
                        key: 'accountNumber',
                        name: formatMessage({id: 'accountNumber'}),
                        minWidth: 100,
                        maxWidth: 100,
                        fieldName: 'accountNumber',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'name',
                            name: formatMessage({id: 'accountDescription'}),
                            minWidth: 200,
                            fieldName: 'name',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'investmentCode',
                            name: formatMessage({id: 'investmentCode'}),
                            minWidth: 100,
                            maxWidth: 128,
                            fieldName: 'investmentCode',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'dateAcquired',
                            name: formatMessage({id: 'dateAcquired'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'dateAcquired',
                            onRender: (item, _, column) => <SanitizedText
                                data={formatDate(item[column?.fieldName || 0])}
                                isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format}/>,
                        },
                        {
                            key: 'dateDisposed',
                            name: formatMessage({id: 'dateDisposed'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'dateDisposed',
                            onRender: (item, _, column) => <SanitizedText
                                data={formatDate(item[column?.fieldName || 0])}
                                isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format}/>,
                        },
                        {
                            key: 'units',
                            name: formatMessage({id: 'units'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'units',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'amountReceived',
                            name: formatMessage({id: 'amountReceived'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'amountReceived',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'costBaseUsed',
                            name: formatMessage({id: 'costBaseUsed'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'costBaseUsed',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'costBaseAdjustment',
                            name: formatMessage({id: 'costBaseAdjustment'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'costBaseAdjustment',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'indexPurchaseSale',
                            name: formatMessage({id: 'gainMethod'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'indexPurchaseSale',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'adjustedCost',
                            name: formatMessage({id: 'adjustedCost'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'adjustedCost',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'gainLoss',
                            name: formatMessage({id: 'gainLoss'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'gainLoss',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        {
                            key: 'lossesRecouped',
                            name: formatMessage({id: 'lossesRecouped'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'lossesRecouped',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        }]
                }
                case TableType.SupermateInvestmentIncome: {
                    return [{
                        key: 'marketName',
                        name: formatMessage({id: 'marketName'}),
                        minWidth: 100,
                        maxWidth: 100,
                        fieldName: 'type',
                        onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                      isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                      format={item?.format}/>,
                    },
                        {
                            key: 'name',
                            name: formatMessage({id: 'investmentName'}),
                            minWidth: 240,
                            fieldName: 'name',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format}/>,
                        },
                        _getReviewedColumn({}),
                        {
                            key: 'totalRevenue',
                            name: formatMessage({id: 'totalRevenue'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'totalRevenue',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'interest',
                            name: formatMessage({id: 'interest'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'interest',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'unfrankedInclCFI',
                            name: formatMessage({id: 'unfrankedInclCFI'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'unfrankedInclCFI',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'franked',
                            name: formatMessage({id: 'franked'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'franked',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'imputationCredits',
                            name: formatMessage({id: 'imputationCredits'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'imputationCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'otherIncome',
                            name: formatMessage({id: 'otherIncome'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'otherIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cgtDiscount',
                            name: formatMessage({id: 'cgtDiscount'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'cgtDiscount',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cgtIndexation',
                            name: formatMessage({id: 'cgtIndexation'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'cgtIndexation',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cgtOther',
                            name: formatMessage({id: 'cgtOther'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'cgtOther',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cgConcession',
                            name: formatMessage({id: 'cgConcession'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'cgConcession',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'cgCredits',
                            name: formatMessage({id: 'cgCredits'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'cgCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'foreignIncome',
                            name: formatMessage({id: 'foreignIncome'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'foreignIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'foreignTaxCredits',
                            name: formatMessage({id: 'foreignTaxCredits'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'foreignTaxCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'nonAssessableTaxFree',
                            name: formatMessage({id: 'nonAssessableTaxFree'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'nonAssessableTaxFree',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxDeferred',
                            name: formatMessage({id: 'taxDeferred'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'taxDeferred',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'taxExempted',
                            name: formatMessage({id: 'taxExempted'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'taxExempted',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'otherCredits',
                            name: formatMessage({id: 'otherCredits'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'otherCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'unfranked',
                            name: formatMessage({id: 'unfranked'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'unfranked',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'foreign',
                            name: formatMessage({id: 'foreign'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'foreign',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },

                        {
                            key: 'tfnabnWithholdTax',
                            name: formatMessage({id: 'tfnabnWithholdTax'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'tfnabnWithholdTax',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'nonResWithholdTax',
                            name: formatMessage({id: 'nonResWithholdTax'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'nonResWithholdTax',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'specialIncome',
                            name: formatMessage({id: 'specialIncome'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'specialIncome',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },
                        {
                            key: 'otherTaxCredits',
                            name: formatMessage({id: 'otherTaxCredits'}),
                            minWidth: 128,
                            maxWidth: 160,
                            fieldName: 'otherTaxCredits',
                            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                                          isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                                          format={item?.format} textAlign='right'
                                                                          numberFormat='fractional'/>,
                        },

                    ]
                }
                default: {
                    return []
                }
            }
        }

        switch (softwareType) {
            case SoftwareType.ClassSuper:
            case SoftwareType.ClassApiInCp:
            case SoftwareType.ClassPdfInCp:
                return _getClassSuperReportColumns;
            case SoftwareType.BGL360:
                return _getBgl360ReportColumns;
            case SoftwareType.BGL:
                return _getBglReportColumns;
            case SoftwareType.Supermate:
                return _getSupermateReportColumns;
            default:
                return (tableType: TableType) => ([]);
        }

    }, [formatMessage, dropdownOptions, handleOnResultChange, tableType])

    const columns: ITableColumn[] = useMemo<ITableColumn[]>(() =>
        [
            {
                key: 'actions',
                name: formatMessage({id: 'actions'}),
                minWidth: 90,
                maxWidth: 90,
                fieldName: 'actions',
                onRender: (item: any, _?: number, column?: IColumn) => (
                    <Stack grow={1} horizontal horizontalAlign='end'>
                        <RedirectButton tabId={item.locationTabId} sectionId={item.locationSectionId} itemId={item.id}/>
                        <JobOmls tableType={tableType} itemId={item.id} hasOmls={item.hasOmls}/>
                        <JobComment itemId={item?.id} hasComments={item.hasComments} tableType={tableType}/>
                    </Stack>
                ),

            },
            ...getReportColumns(job.clientPortalSoftwareType)(tableType),
            {
                key: 'actionTaken',
                name: formatMessage({id: 'actionTaken'}),
                fieldName: 'actionTaken',
                minWidth: 150,
                maxWidth: 150,
                onRender: (item: any, _?: number, column?: IColumn) =>
                    <Stack verticalAlign='center' grow>
                        <DropdownAnswer
                            ref={el => actionTakenAnswerRefs.current[_!] = el}
                            itemId={item.id}
                            options={actionTakenOptions}
                            value={item?.actionTaken}
                            disabled={true}
                            styles={{root: {maxWidth: column?.minWidth}}}
                        />
                    </Stack>
            }
        ], [formatMessage, actionTakenOptions, getReportColumns, isTabEnabled, tableType, job.clientPortalSoftwareType]);

    return (
        <>
            <Stack tokens={{childrenGap: 16}}>
                <Stack.Item>
                    <Text>{formatMessage({id: `conclusionReport_${TableType[tableType as TableType]}`})}</Text>
                </Stack.Item>
                <Stack.Item>
                    <ProceduresDataTable items={(items ?? []).map(i => i.raw || {})}
                                         columns={columns}
                    />
                </Stack.Item>
            </Stack>
        </>
    )
}