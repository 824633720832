import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    FontSizes,
    IButtonStyles,
    IColumn,
    IconButton,
    Link,
    mergeStyles,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Spinner,
    Stack,
    StackItem,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataTable, FileViewer, Modal } from 'components';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useIntl } from 'react-intl';
import { useGetFile } from '../../../../../../hooks';
import { useRemoveJobDocument } from '../../hooks/useRemoveJobDocument';
import { useGetAttachments } from '../../hooks/useGetAttachments';
import { UploadAttachment } from './UploadAttachment';
import { AttachmentsPreview } from './AttachmentsPreview';
import { AttachmentsContext, IAttachmentsContext, useAttachmentsContext } from './AttachmentsContext';
import { IAttachment } from './IAttachment';
import { useCreateAttachment } from '../../hooks/useCreateAttachment';
import { TableType } from '../../../../../../enums';
import { useDropzone } from 'react-dropzone';
import { useGetClientPortalTabAttachments } from '../../hooks/useGetClientPortalTabAttachments';
import { useGetDownloadUrl } from 'hooks/useGetDownloadUrl';
import { useNotifications } from 'components/notifications';

interface IAttachmentDownloadProps {
    downloadUrl: string;
    fileName: string;
}

interface IAttachmentsProps {
    itemId: number;
    hasAttachments?: boolean;
    onLoad?: (countLoaded: number) => void;
    jobId: number;
    fundId: number;
    clientId: number;
    year: number;
    isDeletingEnabled?: boolean;
    tableType: TableType;
}

interface IAttachmentInfoProps {
    fileName: string;
    id: number;
    downloadUrl: string;
    jobId: number;
    isDeletingEnabled?: boolean;
}

interface IAttachmentContentProps {
    isDeletingEnabled?: boolean;
}

const AttachmentContent: FunctionComponent<IAttachmentContentProps> = ({ isDeletingEnabled = true }) => {
    const { formatMessage } = useIntl();
    const attachmentsContext = useAttachmentsContext();

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 350,
            fieldName: 'name',
            onRender: (item) => (
                <AttachmentInfo
                    id={item.id}
                    isDeletingEnabled={isDeletingEnabled}
                    fileName={item.fileName}
                    downloadUrl={item.downloadUrl}
                    jobId={attachmentsContext.jobId}
                />
            ),
        },
    ]);

    if (attachmentsContext.isAttachmentsLoading)
        return (
            <Stack horizontalAlign='center'>
                <Spinner />
            </Stack>
        );
    if (!attachmentsContext.attachments) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {isDeletingEnabled && <UploadAttachment />}
            <DataTable
                initialColumns={columns}
                columns={columns}
                items={attachmentsContext.attachments}
                selectionMode={SelectionMode.none}
                enableShimmer={attachmentsContext.isAttachmentsFetching}
                containerHeight='100%'
            />
        </Stack>
    );
};

export const AttachmentInfo: FunctionComponent<IAttachmentInfoProps> = ({ fileName, id, downloadUrl, isDeletingEnabled = true }) => {
    const theme = useTheme();

    const [viewerState, setViewerState] = useState<{ show: boolean; file: string | null; url: string | null }>({
        show: false,
        file: null,
        url: null,
    });
    return (
        <>
            <Stack horizontal grow verticalAlign='center' horizontalAlign='space-between'>
                <StackItem styles={{ root: { width: '75%', maxWidth: '750px' } }}>
                    <Stack horizontal grow>
                        <Link
                            underline
                            style={{ 
                                color: theme.schemes?.default?.palette.blue,
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                whiteSpace: 'nowrap' 
                            }}
                            onClick={() =>
                                setViewerState({
                                    show: true,
                                    file: fileName,
                                    url: downloadUrl,
                                })
                            }>
                            {fileName}{' '}
                        </Link>
                    </Stack>
                </StackItem>
                <Stack horizontal horizontalAlign='space-between'>
                    <AttachmentDownload downloadUrl={downloadUrl} fileName={fileName} />
                    {isDeletingEnabled && <AttachmentDelete itemId={id} />}
                </Stack>
            </Stack>
            <FileViewer
                fileName={viewerState.file || ''}
                url={viewerState.url || ''}
                onDismiss={() => setViewerState({ show: false, file: null, url: null })}
                isOpen={viewerState.show}
            />
        </>
    );
};

const AttachmentDownload: FunctionComponent<IAttachmentDownloadProps> = ({ downloadUrl, fileName }) => {
    const { getFile } = useGetFile();
    const tooltipId = useId('tooltip');
    const { downloadUrl: directUrl, refetch, remove } = useGetDownloadUrl({ url: downloadUrl });
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (directUrl) {
            getFile({
                url: String(directUrl.data?.downloadUrl),
                fileName: fileName,
            });
            remove();
        }
    }, [directUrl]);

    return (
        <StackItem align='center'>
            <TooltipHost content='Download' id={tooltipId}>
                <IconButton
                    iconProps={{ iconName: 'Download' }}
                    onClick={() => {
                        refetch();
                        showNotification({
                            name: formatMessage({ id: 'downloadStarted' }),
                            type: MessageBarType.info,
                            description: formatMessage({ id: 'downloadStarted' }),
                        });
                    }}
                />
            </TooltipHost>
        </StackItem>
    );
};

interface IAttachmentDeleteProps {
    itemId: number;
}

const AttachmentDelete: FunctionComponent<IAttachmentDeleteProps> = ({ itemId }) => {
    const tooltipId = useId('tooltip');
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { deleteAttachment } = useRemoveJobDocument();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
        }),
        [labelId, subTextId]
    );
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Deleting file',
        closeButtonAriaLabel: 'Close',
        subText: 'Do you want to delete this file?',
        theme: theme.schemes?.default,
    };
    return (
        <StackItem align='center'>
            <TooltipHost content={formatMessage({ id: 'delete' })} id={tooltipId}>
                <IconButton
                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                    iconProps={{ iconName: 'Delete' }}
                    onClick={toggleHideDialog}
                />
            </TooltipHost>
            <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            deleteAttachment(itemId);
                            toggleHideDialog();
                        }}
                        text='Yes'
                    />
                    <DefaultButton onClick={toggleHideDialog} text='No' />
                </DialogFooter>
            </Dialog>
        </StackItem>
    );
};

export const AuditQueryAttachment: FunctionComponent<IAttachmentsProps> = ({
    itemId,
    hasAttachments,
    tableType,
    jobId,
    fundId,
    clientId,
    isDeletingEnabled = true,
}) => {
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const [itemHasAttachments, setItemHasAttachments] = useState(hasAttachments ?? false);

    const { attachmentsData, isAttachmentsLoading, isAttachmentsFetching } = useGetAttachments({
        jobId: jobId,
        itemId: itemId,
    });

    useEffect(() => {
        if (!isAttachmentsLoading) {
            setItemHasAttachments(attachmentsData?.data !== undefined && attachmentsData.data.length > 0);
        }
    }, [attachmentsData?.data]);

    const contextValues: IAttachmentsContext = {
        jobId: jobId,
        clientId: clientId,
        itemId: itemId,
        fundId: fundId,
        attachments: attachmentsData?.data,
        isAttachmentsLoading: isAttachmentsLoading,
        isAttachmentsFetching: isAttachmentsFetching,
        tableType: tableType,
    };

    const theme = useTheme();
    const styles: IButtonStyles = {
        root: {
            color: itemHasAttachments ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: itemHasAttachments ? theme.palette.redDark : theme.palette.themeDarker,
        },
        rootPressed: {
            color: itemHasAttachments ? theme.palette.redDark : theme.palette.themePrimary,
        },
    };

    const [isPreviewVisible, { toggle: toggleIsPreviewVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');

    const handlePointerEnter = () => {
        toggleIsPreviewVisible();
    };

    const handlePointerLeave = () => {
        toggleIsPreviewVisible();
    };

    return (
        <AttachmentsContext.Provider value={contextValues}>
            <IconButton
                id={buttonId}
                iconProps={{ iconName: 'Attach', style: { fontSize: FontSizes.size14 } }}
                styles={styles}
                onClick={toggleOpenModal}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
            />
            {isPreviewVisible && itemHasAttachments && <AttachmentsPreview target={buttonId} />}
            <Modal
                isOpen={isOpenModal}
                onDismiss={() => {
                    toggleOpenModal();
                }}>
                <AttachmentContent isDeletingEnabled={isDeletingEnabled} />
            </Modal>
        </AttachmentsContext.Provider>
    );
};

export const ClientPortalTabAttachment: FunctionComponent<IAttachmentsProps> = ({
    itemId,
    hasAttachments,
    tableType,
    jobId,
    fundId,
    clientId,
    year,
    isDeletingEnabled = true,
}) => {
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const [itemHasAttachments, setItemHasAttachments] = useState(hasAttachments ?? false);

    const { attachmentsData, isAttachmentsLoading, isAttachmentsFetching } = useGetClientPortalTabAttachments({
        jobId: jobId,
        itemId: itemId,
        fundId: fundId,
        year: year,
        tableType: TableType.JobTabAttachment,
    });

    useEffect(() => {
        if (!isAttachmentsLoading) {
            setItemHasAttachments(attachmentsData?.data !== undefined 
                && attachmentsData.data.filter(x => x.jobId === jobId).length > 0);
        }
    }, [attachmentsData?.data, isAttachmentsLoading]);

    const contextValues: IAttachmentsContext = {
        jobId: jobId,
        clientId: clientId,
        itemId: itemId,
        fundId: fundId,
        attachments: attachmentsData?.data.filter(x => x.jobId === jobId),
        isAttachmentsLoading: isAttachmentsLoading,
        isAttachmentsFetching: isAttachmentsFetching,
        tableType: tableType,
    };

    const theme = useTheme();
    const styles: IButtonStyles = {
        root: {
            color: itemHasAttachments ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: itemHasAttachments ? theme.palette.redDark : theme.palette.themeDarker,
        },
        rootPressed: {
            color: itemHasAttachments ? theme.palette.redDark : theme.palette.themePrimary,
        },
    };

    const [isPreviewVisible, { toggle: toggleIsPreviewVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const [isPointerOverPreview, setIsPointerOverPreview] = useState<boolean>(false);

    const handlePointerEnter = () => {
        toggleIsPreviewVisible();
    };

    const handlePointerLeave = () => {
        toggleIsPreviewVisible();
    };

    return (
        <AttachmentsContext.Provider value={contextValues}>
            <IconButton
                id={buttonId}
                iconProps={{ iconName: 'Attach', style: { fontSize: FontSizes.size14 } }}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
                styles={styles}
                onClick={toggleOpenModal}
            />
            {isPreviewVisible && itemHasAttachments && <AttachmentsPreview target={buttonId} />}
            <Modal
                isOpen={isOpenModal}
                onDismiss={() => {
                    toggleOpenModal();
                }}>
                <AttachmentContent isDeletingEnabled={isDeletingEnabled} />
            </Modal>
        </AttachmentsContext.Provider>
    );
};

interface IAttachmentDropZoneProps {
    itemId: number;
    fundId: number;
    jobId: number;
    clientId: number;
    tableType: TableType;
}

export const AttachmentDropZone: FunctionComponent<IAttachmentDropZoneProps> = ({
    itemId,
    children,
    fundId,
    clientId,
    jobId,
    tableType,
}: IAttachmentDropZoneProps & PropsWithChildren<{}>) => {
    const theme = useTheme();

    const { createAttachment, isLoading: isCreateLoading } = useCreateAttachment();

    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [dragCounter, setDragCounter] = useState<number>(0);

    const upload = useCallback((selectedFile) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name);
        createAttachment(
            {
                fundId: fundId,
                jobId: jobId,
                clientId: clientId,
                itemId,
                tableType: tableType,
                data: formData,
            },
            {
                onSuccess: () => {},
            }
        );
    }, []);

    const className = useMemo(() => {
        return mergeStyles({
            '.ms-DetailsRow-fields': {
                backgroundColor: isDragOver ? theme.semanticColors.bodyBackgroundHovered : 'inherit',
            },
            '.ms-Stack': {
                backgroundColor: isDragOver ? theme.semanticColors.bodyBackgroundHovered : 'inherit',
            },
        });
    }, [isDragOver]);

    const onDrop = useCallback((files) => {
        setIsDragOver(false);
        setDragCounter(0);

        if (!files?.length) return;

        Array.from(files).forEach(file => {
            upload(file);
        });
    }, []);

    const { getRootProps } = useDropzone({ onDrop });

    const onDragEnter = useCallback(() => {
        setDragCounter((prev) => prev + 1);
        if (dragCounter === 0) {
            setIsDragOver(true);
        }
    }, [dragCounter]);

    const onDragLeave = useCallback(() => {
        setDragCounter((prev) => --prev);
        if (dragCounter <= 1) {
            setIsDragOver(false);
        }
    }, [dragCounter]);

    return (
        <div onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={() => {}} className={className}>
            <div
                {...getRootProps({
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                })}>
                {children}
            </div>
        </div>
    );
};
